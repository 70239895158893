import router from 'next/router';
import styled from 'styled-components';

import { fontLight, lineLight } from '@constants/colors';
import { laptop } from '@constants/devices';
import { inviteUrl } from '@constants/routes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Text from '@elements/Text';
import Title from '@elements/Title';
import { useGetBookInvitationTokenQuery } from '@graphql/generated/graphql';
import zindex from '@utils/zindex';

import InviteLinkQrCode from './InviteLinkQrCode';

const Component = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  background-color: rgb(10, 31, 68, 0.5);
  z-index: ${zindex('dialog')};
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
`;

const Dialog = styled.div`
  max-width: 95%;
  margin: ${space * 8}px ${space * 3}px;
  position: relative;
  z-index: 1;
  background: white;
  border-radius: 16px;
  border: 1px solid ${lineLight};
  text-align: center;
  padding: ${space * 3}px ${space * 2}px ${space * 3}px;
  box-shadow: 0px 0px 1px 0px rgba(10, 22, 70, 0.06),
    0px 3px 3px -1px rgba(10, 22, 70, 0.1);

  ${laptop} {
    margin: 90px 0;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: default;
`;

const StyledTitle = styled(Title)`
  margin-bottom: ${space * 0}px;
`;

const Description = styled.p`
  margin-bottom: ${space * 3}px;
  padding: ${space}px ${space * 2}px ${space * 3}px;
  font-size: 16px;
  max-width: 400px;
`;

const StyledText = styled(Text)`
  text-align: center;
  color: ${fontLight};
`;

type Props = {
  open: boolean;
  closeDialog?: () => void;
};

const DialogInviteLinkQrCode = ({ open, closeDialog }: Props) => {
  const {
    query: { bookId },
  } = router;
  const { data: inviteToken } = useGetBookInvitationTokenQuery({
    variables: { bookID: bookId },
    skip: !bookId,
  });

  return (
    <Component open={open}>
      <Dialog>
        <StyledTitle size="sm">Scan de QR code</StyledTitle>
        <Description>
          <StyledText size="sm" className="mb-40" weight={500}>
            En ontvang de link op je mobiel.
          </StyledText>
          <InviteLinkQrCode
            inviteUrl={inviteUrl}
            bookId={bookId as string}
            inviteToken={inviteToken || {}}
          />
        </Description>
        <Button onClick={closeDialog} width="75%">
          Doorgaan
        </Button>
      </Dialog>
      <Background onClick={closeDialog} />
    </Component>
  );
};

export default DialogInviteLinkQrCode;
