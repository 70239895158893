import {
  allProducts,
  bookAnniversary,
  bookBirthday,
  bookColleague,
  bookDiner,
  bookHolliday, // bookMemory,
  bookRecipes,
  bookRetirement,
  bookWedding,
} from './routes';

export const themes = [
  {
    theme: 'Verjaardag',
    emoji: '/images/emoji/party-popper.png',
    url: bookBirthday,
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/voorbeelden/verjaardag-40/voorkant-verjaardag-40_ZR7CZNarB.jpg?updatedAt=1715696734690&ik-s=c6ec25d577a207dfcbca333fc3a0975a2987765e',
  },
  {
    theme: '21 diner',
    emoji: '/images/emoji/clinking-glasses.png',
    url: bookDiner,
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/voorbeelden/21-diner/voorkant__2ZhyAgKR.jpg?updatedAt=1715763275513&ik-s=de143433906a8b62ff61a50e2cd7630ece57cffd',
  },
  {
    theme: 'Collega',
    emoji: '/images/emoji/star-struck.png',
    url: bookColleague,
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/voorbeelden/collega-bedankt/achterkant_JrY2sBCtI.jpg?updatedAt=1716404302889&ik-s=22490bef7c9a17a456a00269aeace31b7bf21440',
  },
  {
    theme: 'Jubileum',
    emoji: '/images/emoji/sparkles.png',
    url: bookAnniversary,
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/voorbeelden/60-jaar-getrouwd/voorkant_zHbOWLP22.jpg?updatedAt=1716397526367&ik-s=693f4a2df4804a4d226b367eff544e2cc64c8a91',
  },
  {
    theme: 'Pensioen',
    emoji: '/images/emoji/partying-face.png',
    url: bookRetirement,
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/voorbeelden/pensioen/voorkant_jC3yKXzu6.jpg?updatedAt=1716402210088&ik-s=d22a2df925f5d2b55553500291f4b6a5e35b339a',
  },
  {
    theme: 'Huwelijk',
    emoji: '/images/emoji/white-heart.png',
    url: bookWedding,
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/voorbeelden/trouwen/voorkant_7O7yopgJ5.jpg?updatedAt=1716413924611&ik-s=c837a4b1afcf976029f3596e6b6661c14f580d74',
  },
  {
    theme: 'Vakantie',
    emoji: '/images/emoji/desert-island.png',
    url: bookHolliday,
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/voorbeelden/vakantie/voorkant_768JXWHXy.jpg?updatedAt=1716398799164&ik-s=e91a345e7ebe08ea36c93b09a0e1173217d3c11f',
  },
  {
    theme: 'Recepten',
    emoji: '/images/emoji/cook.png',
    url: bookRecipes,
    image:
      'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/voorbeelden/recepten/voorkant_receptenboek(1)_a2Jac-5He.jpg?updatedAt=1734103669183&ik-s=2d788d4fbbd0ba521590215b5ae66896f1c38843',
  },
  // {
  //   theme: 'Herinnering',
  //   emoji: '/images/emoji/dizzy.png',
  //   url: bookMemory,
  //   image:
  //     'https://ik.imagekit.io/rys2dzldq/belmondo-foto/webshop/voorbeelden/herinnering/voorkant_Rvb6re9bS.jpg?updatedAt=1715761934199&ik-s=7b4149ed38b70ebabdfe0aa36a6317fb67cf30be',
  // },
  { theme: 'En meer +', emoji: '', url: allProducts, image: '' },
];
