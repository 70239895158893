import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { maxWidth } from '@constants/media-queries';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Input from '@elements/Input';

import DropdownWrapper from './DropdownWrapper';
import Notification from './Notification';

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${space}px;

  ${maxWidth.mobile} {
    flex-wrap: wrap;
  }
`;

const StyledDropdownWrapper = styled(DropdownWrapper)`
  ${maxWidth.mobile} {
    width: 100%;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

type InviteLinkProps = {
  inviteURL: string;
  type: 'co-owner' | 'participant';
  buttonStyle?: 'primary' | 'tertiary';
  [x: string]: any;
};

const InviteLink = ({
  inviteURL,
  type,
  buttonStyle = 'tertiary',
  ...rest
}: InviteLinkProps) => {
  const formMethods = useForm();
  const duration = 3000;
  const [shown, setShown] = useState(true);
  const { reset } = formMethods;

  useEffect(() => {
    reset({ 'invite-link': inviteURL });
  }, [reset, inviteURL]);

  const copyTextToClipboard = () => {
    setShown(true);
    if (!navigator.clipboard) return;
    navigator.clipboard
      .writeText(
        `${inviteURL} Doe je mee aan het boek? Het is super eenvoudig. Klik op de link en vul je pagina's. Je kan alles naar wens aanpassen en je bijdragen worden automatisch opgeslagen. Dank je wel!`,
      )
      .then(
        (value) => {
          setTimeout(() => {
            setShown(false);
          }, duration);
        },
        (error) => {
          console.error('Async: Could not copy text: ', error);
        },
      );
  };

  return (
    <FormProvider {...formMethods}>
      <Form {...rest}>
        <Input name="invite-link" defaultValue={inviteURL} disabled />
        <StyledDropdownWrapper
          dropdownChildren={
            shown ? <Notification text="Link gekopieerd!" /> : null
          }
          position="top"
          align="right"
          showMode="onClick"
        >
          <StyledButton
            onClick={copyTextToClipboard}
            onAnimationEnd={() => setShown(false)}
            data-gtm="share_book"
            set={buttonStyle}
          >
            <FontAwesomeIcon icon={faClone} /> Link kopiëren
          </StyledButton>
        </StyledDropdownWrapper>
      </Form>
    </FormProvider>
  );
};

export default InviteLink;
